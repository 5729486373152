<template>
  <v-app id="inspire" style="background: #f8f9fb">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <app-header />
            <v-card class="elevation-3 py-5">
              <v-toolbar color="white blue--text" flat>
                <v-toolbar-title
                  ><span class="c-blue-text"
                    >Create Password</span
                  ></v-toolbar-title
                >
              </v-toolbar>

              <v-card-text class="px-10">
                <v-form ref="createPassword" v-model="valid">
                  <v-text-field
                    label="Password"
                    name="new-password"
                    prepend-icon="mdi-lock"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    v-model="password"
                    @keyup="runValidation()"
                    :rules="[
                      required('Password'),
                      minLength('password', 8),
                      lowerCaseFormat(),
                      upperCaseFormat(),
                      digitFormat(),
                      specialCharFormat(),
                    ]"
                    @click:append="show = !show"
                  ></v-text-field>

                  <v-text-field
                    label="Confirm password"
                    name="confirmPassword1"
                    prepend-icon="mdi-lock"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    v-model="confirmPassword1"
                    :rules="[
                      required('password'),
                      confirmPassword('password', password),
                    ]"
                    @click:append="show1 = !show1"
                  ></v-text-field>

                  <v-card-actions class="px-10">
                    <v-spacer></v-spacer>
                    <v-btn
                      block
                      large
                      color="secondary"
                      class="my-2"
                      @click="createPassword"
                      :disabled="!valid"
                      :loading="loading"
                      >Submit Password</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <app-bottom />
  </v-app>
</template>

<script>
import validations from '@/utils/Validations'
import { mapState } from 'vuex'
export default {
  components: {
    appHeader: () => import('@/components/Logo'),
    appBottom: () => import('@/components/BottomImage'),
  },
  props: {
    source: String,
  },
  data() {
    return {
      valid: false,
      password: '',
      confirmPassword1: '',
      show: false,
      show1: false,
      reValidateConfirmPassword: false,
      ...validations,
    }
  },

  computed: {
    ...mapState(['loading']),
  },

  methods: {
    createPassword() {
      this.$store.state.loading = true

      const formData = {
        password: this.password,
      }

      this.$store.dispatch('createPassword', formData)
    },

    runValidation: function () {
      if (this.confirmPassword1 && this.password)
        this.$refs.createPassword.validate()
    },
  },
}
</script>
